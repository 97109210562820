import type { RouteRecordRaw } from 'vue-router';

const route: RouteRecordRaw = {
  path: '/how-to/hiter-zacetek',
  name: 'hiter-zacetek',
  component: () => import('./Hiter-Zacetek-Index.vue'),
  meta: {
    sidebar: true,
    requiresAuth: true,
    title: 'Kako Uvažati Kanale',
  },
};

export default route;
