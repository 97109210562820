import type { RouteRecordRaw } from "vue-router";

const route: RouteRecordRaw = {
	path: "/how-to/faketec-navodila",
	name: "faketec-navodila",
	component: () => import("./FakeTec-Navodila-Index.vue"),
	meta: {
		sidebar: true,
		requiresAuth: true,
		title: "FakeTec Navodila",
	},
};

export default route;
