import { RouteRecordRaw } from 'vue-router';

const route: RouteRecordRaw = {
  path: '/:notFound',
  name: '404',
  component: () => import('./Stran404-Index.vue'),
  meta: {
    sidebar: false,
    requiresAuth: false,
    title: 'Napaka 404',
  },
};

export default route;
