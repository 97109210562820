import { RouteRecordRaw } from 'vue-router';

const route: RouteRecordRaw = {
  path: '/kanali',
  name: 'kanali',
  component: () => import('./Kanali-Index.vue'),
  meta: {
    sidebar: true,
    requiresAuth: true,
    title: 'Kanali',
  },
};

export default route;
