<template>
  <div class="footer">
    <footer class="flex items-center justify-center">
      <p>
        Powered by
        <a href="https://gitplac.si" target="_blank">Gitplac.si</a>
        |
        <a href="https://gitplac.si/meshnet.si/meshnet.si" target="_blank"
          ><i class="fa fa-gitlab fa-lg"></i> Source code</a
        >
      </p>
    </footer>
  </div>
</template>

<style scoped>
  footer {
    padding: 30px 20px;
    color: #e5e7eb;
    background-color: #1c1e21;
  }
</style>
